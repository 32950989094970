
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"1 min read","minutes":0.405,"time":24300,"words":81}
/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "client-sdks"
    }}>{`Client SDKs`}</h1>
    <h2 {...{
      "id": "php"
    }}>{`PHP`}</h2>
    <p>{`Donec sed tristique massa. Morbi convallis ante quam. Sed pulvinar pretium faucibus. Fusce imperdiet ligula a lectus dictum placerat. Quisque eget nunc justo. Donec mattis commodo risus, at vestibulum nisi auctor id.
Cras at odio vel lectus vehicula condimentum. Fusce mi tellus, viverra vel lacus et, dictum consectetur justo. Etiam eu consectetur leo.`}</p>
    <p><a parentName="p" {...{
        "href": "https://github.com/xqmsg"
      }}>{`PHP SDK`}</a></p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "php",
    level: 2,
    title: "PHP",
    children: [
        
      ]
  }
]

export const frontMatter = {
  "title": "Client SDKs",
  "description": "Client Software Development Kits for development",
  "route": "/docs/sdk/",
  "disableTableOfContents": false
}

