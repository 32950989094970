const root = '/settings';
const baseRoute = (path = '') => `${root}${path}`;

export const accountErrorHashId = 'account-error';

export default {
  root,
  billing: baseRoute('/billing'),
  account: baseRoute('/account'),
  secureFormsPlans: baseRoute('/plans/secure-forms'),
  accountError: baseRoute(`/billing#${accountErrorHashId}`),
} as const;
