import { mode, transparentize, getColor } from '@chakra-ui/theme-tools';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Dict = Record<string, any>;

const baseStyle = {
  lineHeight: 1,
  borderRadius: 'md',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  _focus: {
    boxShadow: 'outline',
  },
  _disabled: {
    opacity: 0.4,
    cursor: 'not-allowed',
    boxShadow: 'none',
  },
};

const focusShadow = (color: string) => `0 0 0 3px ${color}`;

function variantGhost(props: Dict) {
  const { colorScheme: c, theme } = props;

  if (c === 'gray') {
    const focusColor = getColor(theme, 'gray.100');
    return {
      color: mode(`gray.500`, `whiteAlpha.900`)(props),
      _hover: { bg: mode(`gray.50`, `whiteAlpha.200`)(props) },
      _active: { bg: mode(`gray.200`, `whiteAlpha.300`)(props) },
      _focus: { boxShadow: focusShadow(focusColor) },
    };
  }

  const darkHoverBg = transparentize(`${c}.200`, 0.12)(theme);
  const darkActiveBg = transparentize(`${c}.200`, 0.24)(theme);
  const focusColor = getColor(theme, `${c}.200`);

  return {
    color: mode(`${c}.500`, `${c}.200`)(props),
    bg: 'transparent',
    _hover: {
      bg: mode(`${c}.50`, darkHoverBg)(props),
    },
    _active: {
      bg: mode(`${c}.100`, darkActiveBg)(props),
    },
    _focus: {
      boxShadow: focusShadow(focusColor),
    },
  };
}

function variantOutline(props: Dict) {
  const { colorScheme: c } = props;
  const borderColor = mode(`gray.200`, `whiteAlpha.300`)(props);
  return {
    border: '1px solid',
    borderColor: c === 'gray' ? borderColor : 'currentColor',
    ...variantGhost(props),
  };
}

type AccessibleColor = {
  bg?: string;
  color?: string;
  hoverBg?: string;
  activeBg?: string;
};

/** Accessible color overrides for less accessible colors. */
const accessibleColorMap: { [key: string]: AccessibleColor } = {
  yellow: {
    bg: 'yellow.400',
    color: 'black',
    hoverBg: 'yellow.500',
    activeBg: 'yellow.600',
  },
  cyan: {
    bg: 'cyan.400',
    color: 'black',
    hoverBg: 'cyan.500',
    activeBg: 'cyan.600',
  },
};

function variantSolid(props: Dict) {
  const { colorScheme: c, theme } = props;

  if (c === 'gray')
    return {
      color: 'gray.500',
      bg: mode(`gray.50`, `whiteAlpha.200`)(props),
      _hover: { bg: mode(`gray.100`, `whiteAlpha.300`)(props) },
      _active: { bg: mode(`gray.200`, `whiteAlpha.400`)(props) },
    };

  if (c === 'darkgray')
    return {
      color: 'white',
      bg: 'gray.400',
      _hover: { bg: 'gray.600' },
      _active: { bg: 'gray.700' },
    };

  const {
    bg = `${c}.500`,
    color = 'white',
    hoverBg = `${c}.600`,
    activeBg = `${c}.700`,
  } = accessibleColorMap[c] || {};
  const focusColor = getColor(theme, `${c}.200`);
  return {
    bg: mode(bg, `${c}.200`)(props),
    color: mode(color, `gray.800`)(props),
    _hover: { bg: mode(hoverBg, `${c}.300`)(props) },
    _active: { bg: mode(activeBg, `${c}.400`)(props) },
    _focus: {
      boxShadow: focusShadow(focusColor),
    },
  };
}

/**
 * A variant that displays a solid variant when an active CSS class is
 * detected, otherwise display a ghost variant.  This is useful for pill nav filters.
 */
function variantActiveSolid(props: Dict) {
  return {
    ...variantGhost({ ...props, colorScheme: 'gray' }),
    textTransform: 'initial',
    '&.active': {
      ...variantSolid(props),
    },
  };
}

function variantLink(props: Dict) {
  const { colorScheme: c } = props;
  return {
    padding: 0,
    height: 'auto',
    lineHeight: 'normal',
    color: mode(`${c}.500`, `${c}.200`)(props),
    _hover: { textDecoration: 'underline' },
    _active: {
      color: mode(`${c}.700`, `${c}.500`)(props),
    },
  };
}

const variantUnstyled = {
  bg: 'none',
  color: 'inherit',
  display: 'inline',
  lineHeight: 'inherit',
  m: 0,
  p: 0,
};

const variants = {
  ghost: variantGhost,
  outline: variantOutline,
  solid: variantSolid,
  activeSolid: variantActiveSolid,
  link: variantLink,
  unstyled: variantUnstyled,
};

const sizes = {
  lg: {
    borderRadius: 'full',
    h: 12,
    minW: 20,
    fontSize: 'lg',
    textTransform: 'initial',
    px: 6,
  },
  md: {
    borderRadius: 'full',
    h: 10,
    minW: 20,
    fontSize: 'sm',
    px: 4,
  },
  sm: {
    borderRadius: 'full',
    h: 8,
    minW: 8,
    fontSize: 'sm',
    px: 3,
  },
  xs: {
    borderRadius: 'full',
    h: 6,
    minW: 6,
    fontSize: 'xs',
    px: 2,
  },
};

const defaultProps = {
  variant: 'solid',
  size: 'md',
  colorScheme: 'primary',
};

export default {
  baseStyle,
  variants,
  sizes,
  defaultProps,
};
