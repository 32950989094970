const root = '/applications';
const baseRoute = (path = '') => `${root}${path}`;

export default {
  root,
  detail: (id: number | string = ':applicationId') => baseRoute(`/${id}`),

  products: {
    embeddedChat: {
      root: baseRoute('/secure-chat-widget'),
      summary: (id: number | string = ':applicationId') =>
        baseRoute(`/secure-chat-widget/app/${id}`),
      configure: baseRoute('/secure-chat-widget/configure'),
    },
    policyManager: {
      root: baseRoute('/policy-manager'),
      create: baseRoute(`/policy-manager/create`),
      editDataLoss: (id: number | string = ':id') =>
        baseRoute(`/policy-manager/data-loss/${id}`),
      editDataAccess: (id: number | string = ':id') =>
        baseRoute(`/policy-manager/data-access/${id}`),
    },
    secureForm: {
      root: baseRoute('/secure-forms'),
      summary: (id: number | string = ':applicationId') =>
        baseRoute(`/secure-forms/app/${id}`),
      configure: baseRoute('/secure-forms/configure'),
    },
  },
} as const;
