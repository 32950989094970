import { createSlice } from '@reduxjs/toolkit';
import { ThreatGroupTab } from 'key-packet/list/ThreatGroupTabList/constants';
import {
  DateRangePreset,
  dateRangePresetsMap,
} from 'event-log/components/DatePicker/constants';
import { CommunicationFilterMeta } from 'key-packet/list/useCommunicationFilters';

const dateRangePresets: DateRangePreset[] = [
  dateRangePresetsMap.today,
  dateRangePresetsMap.last7Days,
  dateRangePresetsMap.last30Days,
  dateRangePresetsMap.last12Months,
  dateRangePresetsMap.allTime,
];

export const INITIAL_DATE_RANGE_PRESET = dateRangePresets[2];

export interface logsSliceState {
  selectedDateRangePreset?: DateRangePreset;
  isTeamFeed: boolean;
  threatLevel: ThreatGroupTab;
  filterMeta: CommunicationFilterMeta | null;
}

const initialState: logsSliceState = {
  selectedDateRangePreset: INITIAL_DATE_RANGE_PRESET,
  isTeamFeed: false,
  threatLevel: ThreatGroupTab.All,
  filterMeta: null,
};

const logsSlice = createSlice({
  name: 'logs',
  initialState,
  reducers: {
    setSelectedDateRangePreset(state, action) {
      const selectedDateRangePreset = action.payload;
      state.selectedDateRangePreset = selectedDateRangePreset;
    },
    setIsTeamFeed(state, action) {
      const isTeamFeed = action.payload;
      state.isTeamFeed = isTeamFeed;
    },
    setThreatLevel(state, action) {
      const threatLevel = action.payload;
      state.threatLevel = threatLevel;
    },
    setFilterMeta(state, action) {
      const filterMeta = action.payload;
      state.filterMeta = filterMeta;
    },
  },
});

export const {
  setSelectedDateRangePreset,
  setIsTeamFeed,
  setThreatLevel,
  setFilterMeta,
} = logsSlice.actions;

export default logsSlice.reducer;
