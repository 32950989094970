/* eslint-disable import/no-webpack-loader-syntax */
import * as Authentication from '!babel-loader!mdx-loader!docs/Authentication.mdx';
import * as Documentation from '!babel-loader!mdx-loader!docs/Documentation.mdx';
import * as APIReference from '!babel-loader!mdx-loader!docs/APIReference.mdx';
import * as WebHooks from '!babel-loader!mdx-loader!docs/WebHooks.mdx';
import * as Applications from '!babel-loader!mdx-loader!docs/Applications.mdx';
import * as Keys from '!babel-loader!mdx-loader!docs/Keys.mdx';
import * as Subscriber from '!babel-loader!mdx-loader!docs/Subscriber.mdx';
import * as ClientLibraries from '!babel-loader!mdx-loader!docs/ClientLibraries.mdx';
import * as SampleApplications from '!babel-loader!mdx-loader!docs/SampleApplications.mdx';
import * as ClientSDK from '!babel-loader!mdx-loader!docs/ClientSDK.mdx';
import * as AboutXQ from '!babel-loader!mdx-loader!docs/about/AboutXQ.mdx';
import * as ContactUS from '!babel-loader!mdx-loader!docs/about/ContactUS.mdx';
import * as TermsOfService from '!babel-loader!mdx-loader!docs/about/TermsOfService.mdx';
import * as SecurityAtXQ from '!babel-loader!mdx-loader!docs/about/SecurityAtXQ.mdx';
import * as PrivacyPolicy from '!babel-loader!mdx-loader!docs/about/PrivacyPolicy.mdx';

function routeFor(d: { frontMatter: { title?: string; route?: string } }) {
  return d.frontMatter.route || '/no-such-route';
}

export const routes = {
  about: routeFor(AboutXQ),
  apiReference: routeFor(APIReference),
  applications: routeFor(Applications),
  auth: routeFor(Authentication),
  clientSDKs: routeFor(ClientSDK),
  clients: routeFor(ClientLibraries),
  contact: routeFor(ContactUS),
  keys: routeFor(Keys),
  privacy: routeFor(PrivacyPolicy),
  root: routeFor(Documentation),
  sampleApplications: routeFor(SampleApplications),
  sampleApps: routeFor(SampleApplications),
  security: routeFor(SecurityAtXQ),
  subscriber: routeFor(Subscriber),
  terms: routeFor(TermsOfService),
  webHooks: routeFor(WebHooks),
} as const;

export const pages = [
  APIReference,
  AboutXQ,
  Applications,
  Authentication,
  ClientSDK,
  ContactUS,
  Documentation,
  Keys,
  PrivacyPolicy,
  SampleApplications,
  SecurityAtXQ,
  Subscriber,
  TermsOfService,
  WebHooks,
];
