import { mode } from '@chakra-ui/theme-tools';

const parts = [
  'overlay',
  'dialogContainer',
  'dialog',
  'header',
  'closeButton',
  'body',
  'footer',
];

const baseStyleOverlay = {
  bg: 'blackAlpha.600',
  zIndex: 'modal',
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Dict = Record<string, any>;

function baseStyleDialogContainer(props: Dict) {
  const { scrollBehavior } = props;

  return {
    display: 'flex',
    zIndex: 'modal',
    justifyContent: 'center',
    alignItems: 'flex-start',
    overflow: scrollBehavior === 'inside' ? 'hidden' : 'auto',
    WebkitOverflowScrolling: 'touch',
  };
}

function baseStyleDialog(props: Dict) {
  const { isCentered, scrollBehavior } = props;

  return {
    borderRadius: 'lg',
    bg: mode('white', 'gray.700')(props),
    color: 'inherit',
    mt: isCentered ? { base: 8, md: 'auto' } : '3.75rem',
    mb: isCentered ? { base: 20, md: 'auto' } : '3.75rem',
    mx: 4,
    zIndex: 'modal',
    maxH: scrollBehavior === 'inside' ? 'calc(100vh - 7.5rem)' : undefined,
    boxShadow: mode('xl', 'dark-lg')(props),
  };
}

const baseStyleHeader = {
  px: 6,
  py: 4,
  minHeight: 20,
  fontSize: 'xl',
  display: 'flex',
  alignItems: 'center',
  fontWeight: 'bold',
  bg: 'primary.900',
  color: 'white',
  userSelect: 'none',
};

const baseStyleCloseButton = {
  position: 'absolute',
  top: 2,
  right: 3,
};

function baseStyleBody(props: Dict) {
  const { scrollBehavior } = props;
  return {
    px: [6, null, 20],
    py: 10,
    flex: 1,
    overflow: scrollBehavior === 'inside' ? 'auto' : undefined,
  };
}

const baseStyleFooter = {
  px: [6, null, 20],
  pt: 5,
  pb: 16,
};

const baseStyle = (props: Dict) => ({
  overlay: baseStyleOverlay,
  dialogContainer: baseStyleDialogContainer(props),
  dialog: baseStyleDialog(props),
  header: baseStyleHeader,
  closeButton: baseStyleCloseButton,
  body: baseStyleBody(props),
  footer: baseStyleFooter,
});

/**
 * Since the `maxWidth` prop references theme.sizes internally,
 * we can leverage that to size our modals.
 */
function getSize(value: string) {
  const commonBodyFooterStyles: Dict = {
    xs: {
      px: 8,
      pb: 8,
    },
    sm: {
      px: 8,
      pb: 8,
    },
    md: {
      px: 8,
      pb: 8,
    },
    lg: {
      px: 10,
      pb: 10,
    },
    xl: {
      px: 12,
      pb: 10,
    },
  };

  const bodyStyles: Dict = {
    xs: {
      py: 6,
    },
    sm: {
      py: 6,
    },
    md: {
      py: 6,
    },
    lg: {
      py: 8,
    },
    xl: {
      py: 8,
    },
  };

  const footerStyles: Dict = {
    xs: {
      pb: 8,
    },
    sm: {
      pb: 8,
    },
    md: {
      pb: 8,
    },
    lg: {
      pb: 10,
    },
    xl: {
      pb: 10,
    },
  };

  if (value === 'full') {
    return { content: { maxW: '100vw', h: '100vh' } };
  }

  return {
    content: {
      maxW: value,
    },
    body: {
      ...commonBodyFooterStyles[value],
      ...bodyStyles[value],
    },
    footer: {
      ...commonBodyFooterStyles[value],
      ...footerStyles[value],
    },
  };
}

const sizes = {
  xs: getSize('xs'),
  sm: getSize('sm'),
  md: getSize('md'),
  lg: getSize('lg'),
  xl: getSize('xl'),
  '2xl': getSize('2xl'),
  '3xl': getSize('3xl'),
  '4xl': getSize('4xl'),
  '5xl': getSize('5xl'),
  '6xl': getSize('6xl'),
  full: getSize('full'),
};

const warningVariant = () => {
  return {
    header: {
      bg: 'warning.300',
      color: 'black',
    },
  };
};

const variants = {
  warning: warningVariant,
};

const defaultProps = {
  size: 'lg',
  isCentered: true,
};

export default {
  parts,
  baseStyle,
  sizes,
  variants,
  defaultProps,
};
