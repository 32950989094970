import Input from './input';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Dict = Record<string, any>;

const baseStyle = {
  ...Input.baseStyle.field,
  display: 'block', // Removes gap below textarea because it defaults to inline.
  paddingY: '8px',
  minHeight: '80px',
  lineHeight: 'short',
};

const codeVariant = (props: Dict) => {
  const { _focus } = Input.variants.outline(props).field;
  return {
    bg: 'blue.900',
    color: 'white',
    fontFamily: 'mono',
    fontSize: 'sm',
    border: '1px',
    borderColor: 'blue.900',
    borderRadius: 'lg',
    p: 6,
    wordBreak: 'break-all',
    _focus,
  };
};

const variants = {
  outline: (props: Dict) => Input.variants.outline(props).field,
  flushed: (props: Dict) => Input.variants.flushed(props).field,
  filled: (props: Dict) => Input.variants.filled(props).field,
  code: (props: Dict) => codeVariant(props),
  unstyled: Input.variants.unstyled.field,
};

const sizes = {
  sm: Input.sizes.sm.field,
  md: Input.sizes.md.field,
  lg: Input.sizes.lg.field,
};

const defaultProps = {
  size: 'lg',
  variant: 'outline',
};

export default {
  baseStyle,
  sizes,
  variants,
  defaultProps,
};
