import { mode } from '@chakra-ui/theme-tools';
import Input from './input';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Dict = Record<string, any>;

const { defaultProps, variants } = Input;

const parts = ['field', 'icon'];

// Move this over here as padding right that was being imported from './input' interferes with the chevron icon
const size = {
  lg: {
    fontSize: 'md',
    pl: 3,
    h: 12,
    borderRadius: 'md',
  },

  md: {
    fontSize: 'md',
    pl: 3,
    h: 10,
    borderRadius: 'md',
  },

  sm: {
    fontSize: 'sm',
    pl: 3,
    h: 8,
    borderRadius: 'sm',
  },
};

const sizes = {
  lg: {
    field: size.lg,
    addon: size.lg,
  },
  md: {
    field: size.md,
    addon: size.md,
  },
  sm: {
    field: size.sm,
    addon: size.sm,
  },
};

function baseStyleField(props: Dict) {
  return {
    ...Input.baseStyle.field,
    appearance: 'none',
    paddingBottom: '1px',
    lineHeight: 'normal',
    bg: 'white',
    '> option': {
      bg: mode('white', 'gray.700')(props),
    },
  };
}

const baseStyleInput = {
  color: 'currentColor',
  fontSize: '1.25rem',
  _disabled: { opacity: 0.5 },
};

const baseStyle = (props: Dict) => ({
  field: baseStyleField(props),
  icon: baseStyleInput,
});

export default {
  parts,
  baseStyle,
  sizes,
  variants,
  defaultProps,
};
