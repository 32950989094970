import { getColor, mode, transparentize } from '@chakra-ui/theme-tools';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Dict = Record<string, any>;

const parts = ['field', 'addon'];

const baseStyle = {
  field: {
    width: '100%',
    outline: 0,
    position: 'relative',
    appearance: 'none',
    transition: 'all 0.2s',
    '::placeholder': {
      color: 'gray.300',
    },
  },
};

const size = {
  lg: {
    fontSize: 'md',
    pl: 3,
    pr: 3,
    h: 12,
    borderRadius: 'md',
  },

  md: {
    fontSize: 'md',
    pl: 3,
    pr: 3,
    h: 10,
    borderRadius: 'md',
  },

  sm: {
    fontSize: 'sm',
    pl: 3,
    pr: 3,
    h: 8,
    borderRadius: 'sm',
  },
};

const sizes = {
  lg: {
    field: size.lg,
    addon: size.lg,
  },
  md: {
    field: size.md,
    addon: size.md,
  },
  sm: {
    field: size.sm,
    addon: size.sm,
  },
};

function getDefaults(props: Dict) {
  const { focusBorderColor: fc, errorBorderColor: ec } = props;
  return {
    focusBorderColor: fc || mode('blue.500', 'blue.300')(props),
    errorBorderColor: ec || mode('red.500', 'red.300')(props),
  };
}

function variantOutline(props: Dict) {
  const { theme } = props;
  const { focusBorderColor: fc, errorBorderColor: ec } = getDefaults(props);

  return {
    field: {
      border: '1px solid',
      borderColor: 'inherit',
      bg: 'white',
      _hover: {
        borderColor: mode('gray.300', 'whiteAlpha.400')(props),
      },
      _readOnly: {
        boxShadow: 'none !important',
        userSelect: 'all',
      },
      _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
        color: 'gray.300',
      },
      _focus: {
        zIndex: 1,
        borderColor: getColor(theme, fc),
        boxShadow: `0 0 0 2px ${transparentize(fc, 0.3)(theme)}`,
      },
      _invalid: {
        borderColor: getColor(theme, ec),
        boxShadow: 'none',
        _focus: {
          boxShadow: `0 0 0 2px ${transparentize(ec, 0.3)(theme)}`,
        },
      },
    },
    addon: {
      border: '1px solid',
      borderColor: mode('inherit', 'whiteAlpha.50')(props),
      bg: mode('gray.100', 'whiteAlpha.300')(props),
    },
  };
}

function variantFilled(props: Dict) {
  const { theme } = props;
  const { focusBorderColor: fc, errorBorderColor: ec } = getDefaults(props);

  return {
    field: {
      border: '2px solid',
      borderColor: 'transparent',
      bg: mode('gray.50', 'whiteAlpha.50')(props),
      _hover: {
        bg: mode('gray.100', 'whiteAlpha.100')(props),
      },
      _readOnly: {
        boxShadow: 'none !important',
        userSelect: 'all',
      },
      _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
        color: 'gray.300',
      },
      _focus: {
        bg: 'transparent',
        borderColor: getColor(theme, fc),
      },
      _invalid: {
        borderColor: getColor(theme, ec),
      },
    },
    addon: {
      border: '2px solid',
      borderColor: 'transparent',
      bg: mode('gray.100', 'whiteAlpha.50')(props),
    },
  };
}

function variantFlushed(props: Dict) {
  const { theme } = props;
  const { focusBorderColor: fc, errorBorderColor: ec } = getDefaults(props);

  return {
    field: {
      borderBottom: '1px solid inherit',
      borderRadius: 0,
      pl: 0,
      pr: 0,
      bg: 'transparent',
      _readOnly: {
        boxShadow: 'none !important',
        userSelect: 'all',
      },
      _focus: {
        borderColor: getColor(theme, fc),
        boxShadow: `0px 1px 0px 0px ${getColor(theme, fc)}`,
      },
      _invalid: {
        borderColor: getColor(theme, ec),
      },
    },
    addon: {
      borderBottom: '2px solid',
      borderColor: 'inherit',
      borderRadius: 0,
      paddingX: 0,
      bg: 'transparent',
    },
  };
}

const variantUnstyled = {
  field: {
    bg: 'transparent',
    pl: 0,
    pr: 0,
    height: 'auto',
  },
  addon: {
    bg: 'transparent',
    pl: 0,
    pr: 0,
    height: 'auto',
  },
};

const variants = {
  outline: variantOutline,
  filled: variantFilled,
  flushed: variantFlushed,
  unstyled: variantUnstyled,
};

const defaultProps = {
  size: 'lg',
  variant: 'outline',
};

export default {
  parts,
  baseStyle,
  sizes,
  variants,
  defaultProps,
};
