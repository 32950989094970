
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"1 min read","minutes":0.365,"time":21900,"words":73}
/* @jsxRuntime classic */
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const CodeView = makeShortcode("CodeView");
const Code = makeShortcode("Code");
const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "applications"
    }}>{`Applications`}</h1>
    <p>{`Creating applications, why are they created.`}</p>
    <h2 {...{
      "id": "different-application-types"
    }}>{`Different application types`}</h2>
    <p>{`What are the types of applications I can create?`}</p>
    <h2 {...{
      "id": "application-keys"
    }}>{`Application Keys`}</h2>
    <p>{`Managing application keys`}</p>
    <CodeView mdxType="CodeView">
  <Code language="javascript" title="Javascript" mdxType="Code">
    {`
var a = 1;
console.log('Hello');
`}
  </Code>
  <Code language="php" title="PHP" mdxType="Code">
    {`
<?php
  echo "Hello World!";
  echo "PHP is so easy!";
?>
`}
  </Code>
    </CodeView>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "different-application-types",
    level: 2,
    title: "Different application types",
    children: [
        
      ]
  },
{
    id: "application-keys",
    level: 2,
    title: "Application Keys",
    children: [
        
      ]
  }
]

export const frontMatter = {
  "title": "Application Management",
  "description": "Application Management",
  "route": "/docs/applications",
  "disableTableOfContents": false
}

