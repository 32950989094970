import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box } from '@chakra-ui/react';
import LoadingBox from 'shared/components/LoadingBox';
import ReactGA from 'react-ga';
import routes from './routes';

const PricingRoutes = lazy(
  () => import('./shared/components/Squarespace/PricingRoutes')
);
const AuthRoutes = lazy(() => import('./auth/AuthRoutes'));
const DashboardRoutes = lazy(() => import('./dashboard/DashboardRoutes'));

const App: React.FC = () => {
  const uaCode = process.env.REACT_APP_UA_CODE;
  ReactGA.initialize(uaCode ?? '');
  ReactGA.ga('create', {
    trackingId: uaCode,
    cookieDomain: 'auto',
    name: 'clientTracker',
  });

  return (
    <>
      {/* Default meta tags for the application */}
      <Helmet>
        <title>XQ Zero Trust Data Protection Platform Management Portal</title>
        <meta
          name="description"
          content="XQ’s Zero Trust Data Protection Platform is the ideal solution for email encryption, file sharing, VPN Replacement, and Quantum Resistant Vault on your S3 and Azure Blob. The remote tracking and control features of each XQ data object provide data provenance and residency for compliance with CMMC, ITAR, GDPR, HIPAA, and FINRA regulations"
        />
      </Helmet>
      <Suspense
        fallback={
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100vh"
          >
            <LoadingBox text="" />
          </Box>
        }
      >
        <Switch>
          <Route
            path={[
              routes.auth.login,
              routes.auth.code,
              routes.auth.forgotPassword,
              routes.auth.signup,
              routes.auth.verify,
              routes.auth.accountVerified,
            ]}
            component={AuthRoutes}
            exact
          />
          {/*  NOTE: keep here for reference in case we add back in */}
          {/* {!isSignedIn && ( */}
          <Route
            path={[
              routes.pricing.root,
              routes.pricing.communications,
              routes.pricing.vault,
              routes.pricing.transfer,
            ]}
            component={PricingRoutes}
            exact
          />
          {/* )} */}

          {/* Default route is the main application page */}
          <Route>
            <DashboardRoutes />
          </Route>
        </Switch>
      </Suspense>
    </>
  );
};

export default App;
