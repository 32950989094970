import {
  createBreakpoints,
  BaseBreakpointConfig,
} from '@chakra-ui/theme-tools';

/**
 * Breakpoints for responsive design
 */
const breakpointValues: BaseBreakpointConfig = {
  sm: '30em',
  md: '48em',
  lg: '62em',
  xl: '80em',
};

const breakpoints = createBreakpoints(breakpointValues);

export default breakpoints;
