import React from 'react';
import { Flex, FlexProps, Spinner, SpinnerProps, Text } from '@chakra-ui/react';

export interface LoadingBoxProps extends FlexProps {
  text?: string;
  spinnerSize?: SpinnerProps['size'];
}

const LoadingBox: React.FC<LoadingBoxProps> = ({
  text = 'Loading',
  spinnerSize = 'xl',
  ...rest
}) => {
  const direction = rest.direction || rest.flexDirection || 'column';
  const marginProp = direction.toString().startsWith('column') ? 'mb' : 'mr';
  return (
    <Flex
      direction={direction}
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      bg="transparent"
      m="auto"
      py={4}
      px={6}
      {...rest}
    >
      <Spinner
        flex="none"
        thickness="3px"
        speed="0.5s"
        emptyColor="gray.50"
        color="blue.500"
        size={spinnerSize}
        sx={{
          [marginProp]: 2,
        }}
      />
      {text && (
        <Text fontSize="xl" lineHeight="shorter" fontWeight="semibold">
          {text}
        </Text>
      )}
    </Flex>
  );
};

export default LoadingBox;
