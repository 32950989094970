import { createSlice } from '@reduxjs/toolkit';
import { DestinationPoint } from 'key-packet/list/MapTracing/hooks/useGetEventLogs';
import { GroupedOriginPointProperties } from 'key-packet/list/MapTracing/types';
import { CommunicationSummary } from 'key-packet/types';
import { ViewportProps } from 'react-map-gl';
import { PointFeature } from 'supercluster';

export interface mapSliceState {
  groupedOriginPoints: PointFeature<GroupedOriginPointProperties>[];
  destinationPoints: DestinationPoint[];
  traceRouteDestinationPoints: DestinationPoint[];
  activeOriginPoint: PointFeature<GroupedOriginPointProperties> | null;
  currentViewport: ViewportProps;
  activeCommunication: CommunicationSummary | null;
  fitBounds: boolean;
  isLoadingLogs: boolean;
  activePopover: PointFeature<GroupedOriginPointProperties> | null;
}

const initialState: mapSliceState = {
  currentViewport: {
    longitude: 0,
    latitude: 0,
    zoom: 0,
  },
  activePopover: null,
  isLoadingLogs: false,
  fitBounds: true,
  activeCommunication: null,
  groupedOriginPoints: [],
  activeOriginPoint: null,
  destinationPoints: [],
  traceRouteDestinationPoints: [],
};

const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setFitBounds(state, action) {
      const fitBounds = action.payload;
      state.fitBounds = fitBounds;
    },
    setActivePopover(state, action) {
      const activePopover = action.payload;
      state.activePopover = activePopover;
    },

    setCurrentViewport(state, action) {
      const currentViewport = action.payload;
      state.currentViewport = currentViewport;
    },
    setActiveCommunication(state, action) {
      const activeCommunication = action.payload;
      state.activeCommunication = activeCommunication;
    },
    setGroupedOriginPoints(state, action) {
      const groupedOriginPoints = action.payload;
      state.groupedOriginPoints = groupedOriginPoints;
    },
    setActiveOriginPoint(state, action) {
      const activeOriginPoint = action.payload;
      state.activeOriginPoint = activeOriginPoint;
    },
    setIsLoadingLogs(state, action) {
      const isLoadingLogs = action.payload;
      state.isLoadingLogs = isLoadingLogs;
    },
    setDestinationPoints(state, action) {
      const destinationPoints = action.payload;
      state.destinationPoints = destinationPoints;
    },
    setTraceRouteDestinationPoints(state, action) {
      const traceRouteDestinationPoints = action.payload;
      state.traceRouteDestinationPoints = traceRouteDestinationPoints;
    },
    resetMap() {
      return initialState;
    },
  },
});

export const {
  setCurrentViewport,
  setActiveCommunication,
  setActiveOriginPoint,
  setDestinationPoints,
  setGroupedOriginPoints,
  setIsLoadingLogs,
  setTraceRouteDestinationPoints,
  resetMap,
  setFitBounds,
  setActivePopover,
} = mapSlice.actions;

export default mapSlice.reducer;
