const baseStyle = () => ({
  control: {
    _checked: {
      _disabled: {
        bg: '#E8E8E8',
        border: '1.2px solid #DDDEDF',
        color: '#C4C4C4',
      },
    },
  },
});

const parts = ['control'];

export default {
  parts,
  baseStyle,
};
