
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"5 min read","minutes":4.79,"time":287400,"words":958}
/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><img parentName="p" {...{
        "src": "https://xqmessage.com/api/client/img/crossplatform_api.svg",
        "alt": null
      }}></img></p>
    <h1 {...{
      "id": "an-introduction-to-apps"
    }}>{`An Introduction To Apps`}</h1>
    <p>{`Tour the fundamentals of the XQ app platform. Take a journey through the creation and development of your first app.`}</p>
    <h2 {...{
      "id": "planning-your-app"
    }}>{`Planning Your App`}</h2>
    <p>{`XQ is here to help you encrypt and manage your data on whichever platform your app lives. XQ can help protect your business and your customers across mobile phones, IOT devices, through browser wizardry, and enchanted server side applications.`}</p>
    <h2 {...{
      "id": "an-introduction-to-the-xq-platform"
    }}>{`An Introduction To The XQ Platform`}</h2>
    <p>{`The XQ platform helps you encrypt your data using quantum-safe entropy. The XQ API performs 3 basic tasks: it supplies entropy (which may be used as a key or to seed an encryption key) , it manages data policies, stores and retrieves keys, and validates token authorization.`}</p>
    <p>{`The encryption events associated with your data and your keys will be tracked in your organization dashboard and associated with your application ID. This will help you maintain security and understand how, where and by whom your data is being encrypted and decrypted.`}</p>
    <p>{`XQ offers an edge-based encryption framework that is super fast, super lightweight and ultra strong. Encrypt data on and between any device, application or service on any network including 5G.`}</p>
    <p>{`Keep reading to get an overview of our platform’s features. We’ll point you in the right direction to learn even more about each piece along the way.`}</p>
    <h2 {...{
      "id": "what-can-the-xq-platform-do"
    }}>{`What Can The XQ Platform Do?`}</h2>
    <p>{`The XQ platform offers a couple of different routes to enhance your application encryption: Encrypt data and files at the edge with our super light-weight framework.`}</p>
    <p>{`Our SDKs offer simple examples for how to get going very fast.
You can use XQ encryption workflows in all types of apps on their own, or have them communicate across apps to various platforms for seamless experience.
XQ Apps: Go Beyond Messaging Using APIs`}</p>
    <p>{`Apps have access to the full scope of the platform, paving the way for more enhanced, custom, and powerful experiences in your own apps.`}</p>
    <p>{`To make this magic happen, every XQ app has access to a stock of resources — a range of APIs that provide access to encryption, validation, and subscription management of all kinds of data.`}</p>
    <p>{`Some of these features mirror what users can do in XQ clients:`}</p>
    <ul>
      <li parentName="ul">{`Send messages to all kinds of conversations using Rest APIs and JavaScript Libraries.`}</li>
      <li parentName="ul">{`Create, expire, and manage conversations and files.`}</li>
      <li parentName="ul">{`Learn the process of adding features to your application with guides on planning your XQ app.`}</li>
      <li parentName="ul">{`Develop a toolset to construct your XQ apps with guides on building XQ apps.`}</li>
    </ul>
    <h2 {...{
      "id": "where-apps-live"
    }}>{`Where Apps Live`}</h2>
    <p>{`XQ can be embedded into any application you develop that can reach the internet. Register your application and receive an application ID associated with your dashboard account. Use that application ID to make REST calls to our API in order to manage your encryption policies and keys.`}</p>
    <h2 {...{
      "id": "management-of-apps"
    }}>{`Management of Apps`}</h2>
    <p>{`Manage your XQ apps by travelling to the app dashboard. There you’ll find a list of the apps you own or collaborate with and the tracked data for your app’s encryption events. Just click on the name of an app to configure that app’s settings.`}</p>
    <p>{`Can’t find the app you’re looking for? Make sure you’re signed in to the associated dashboard and reload as necessary`}</p>
    <h2 {...{
      "id": "collaborating-on-apps"
    }}>{`Collaborating on Apps`}</h2>
    <p>{`The development of XQ apps doesn’t have to be a solo endeavour. Add App Collaborators to help manage the app’s settings and App Directory listings.`}</p>
    <p>{`All Admins in your organization dashboard are collaborators and have access to app tracking data and settings.`}</p>
    <p>{`Collaborators are able to perform important actions like deleting apps, and managing and removing other collaborators (including you, the app creator). With that in mind, be very deliberate about who you add as a collaborator.`}</p>
    <p>{`Within your app dashboard’s Team tab, you’ll find an Admins filter that will allow you to add and remove collaborators.`}</p>
    <p>{`If you have them, you may assign your workspace’s single or multi-channel guests as App Collaborators. These guests cannot delete a XQ app, nor modify your carefully pruned roster of co-conspirators.`}</p>
    <h2 {...{
      "id": "building-your-apps"
    }}>{`Building Your Apps`}</h2>
    <p>{`The first steps on a newly minted XQ app can be a little daunting. To give you a nudge in the right direction, we’ve created a guide to help plan the app you’ll build.`}</p>
    <p>{`We also provide a set of resources, SDKs, and tools to make the app building process faster and easier. Read our guide to building a XQ app to discover them.`}</p>
    <p>{`Keep reading to learn about how you can install and distribute the XQ apps that you’ve built.`}</p>
    <h2 {...{
      "id": "xq-doesnt-have-your-data"
    }}>{`XQ Doesn’t Have Your Data`}</h2>
    <p>{`What is key to the XQ ethos is the fact that we don’t hold your data. XQ provides you with the tools to encrypt, decrypt and manage the keys for your data, but you always hold on to it.`}</p>
    <h2 {...{
      "id": "distributing-apps"
    }}>{`Distributing Apps`}</h2>
    <p>{`XQ apps are bound to organization dashboards. You may integrate the XP API into your apps and distribute them on any platform as long as it complies with our current terms of service.`}</p>
    <h2 {...{
      "id": "your-next-steps"
    }}>{`Your Next Steps`}</h2>
    <p>{`You might have found something to raise your interest as you went through this overview. But if you kept reading to this point, here’s a reminder of what we recommend as next steps.`}</p>
    <p>{`We explained some of the features available to XQ apps through API integration? We have a section dedicated to planning your XQ app that will guide you on the right course.`}</p>
    <p>{`When it comes time to build that app, you don’t need to write everything from scratch. Take advantage of our SDKs and developer tools to make the job simpler and quicker.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "planning-your-app",
    level: 2,
    title: "Planning Your App",
    children: [
        
      ]
  },
{
    id: "an-introduction-to-the-xq-platform",
    level: 2,
    title: "An Introduction To The XQ Platform",
    children: [
        
      ]
  },
{
    id: "what-can-the-xq-platform-do",
    level: 2,
    title: "What Can The XQ Platform Do?",
    children: [
        
      ]
  },
{
    id: "where-apps-live",
    level: 2,
    title: "Where Apps Live",
    children: [
        
      ]
  },
{
    id: "management-of-apps",
    level: 2,
    title: "Management of Apps",
    children: [
        
      ]
  },
{
    id: "collaborating-on-apps",
    level: 2,
    title: "Collaborating on Apps",
    children: [
        
      ]
  },
{
    id: "building-your-apps",
    level: 2,
    title: "Building Your Apps",
    children: [
        
      ]
  },
{
    id: "xq-doesnt-have-your-data",
    level: 2,
    title: "XQ Doesn’t Have Your Data",
    children: [
        
      ]
  },
{
    id: "distributing-apps",
    level: 2,
    title: "Distributing Apps",
    children: [
        
      ]
  },
{
    id: "your-next-steps",
    level: 2,
    title: "Your Next Steps",
    children: [
        
      ]
  }
]

export const frontMatter = {
  "title": "XQ Documentation",
  "description": "Documentation",
  "route": "/docs/",
  "disableTableOfContents": false
}

