import {
  endOfDay,
  endOfWeek,
  endOfYear,
  startOfDay,
  startOfWeek,
  startOfYear,
  sub,
} from 'date-fns';

const now = new Date();

export type DateRange = {
  fromDate: Date;
  toDate: Date;
};

export type DateRangePreset = {
  label: string;
  dateRange: DateRange;
  format: string;
};

export const dateRangePresetsMap: Record<string, DateRangePreset> = {
  today: {
    label: 'Today',
    dateRange: {
      fromDate: startOfDay(now),
      toDate: endOfDay(now),
    },
    format: 'ha',
  },
  yesterday: {
    label: 'Yesterday',
    dateRange: {
      fromDate: sub(startOfDay(now), { days: 1 }),
      toDate: endOfDay(sub(startOfDay(now), { days: 1 })),
    },
    format: 'ha',
  },
  thisWeek: {
    label: 'This Week',
    dateRange: {
      fromDate: startOfWeek(now),
      toDate: endOfWeek(now),
    },
    format: 'LLL d',
  },
  lastWeek: {
    label: 'Last Week',
    dateRange: {
      fromDate: startOfWeek(sub(startOfWeek(now), { days: 1 })),
      toDate: endOfWeek(sub(startOfWeek(now), { days: 1 })),
    },
    format: 'LLL d',
  },
  last7Days: {
    label: 'Last 7 Days',
    dateRange: {
      fromDate: sub(startOfDay(now), { days: 7 }),
      toDate: endOfDay(now),
    },
    format: 'LLL d',
  },
  last30Days: {
    label: 'Last 30 Days',
    dateRange: {
      fromDate: sub(startOfDay(now), { days: 30 }),
      toDate: endOfDay(now),
    },
    format: 'LLL d',
  },
  last12Months: {
    label: 'Last 12 Months',
    dateRange: {
      fromDate: sub(startOfDay(now), { months: 12 }),
      toDate: endOfDay(now),
    },
    format: 'MMM y',
  },
  lastYear: {
    label: 'Last Year',
    dateRange: {
      fromDate: startOfYear(sub(now, { years: 1 })),
      toDate: endOfYear(sub(now, { years: 1 })),
    },
    format: 'MMM y',
  },
  allTime: {
    label: 'All Time',
    dateRange: {
      fromDate: new Date(1970, 1, 1, 0, 0, 0, 0),
      toDate: endOfDay(now),
    },
    format: 'MMM y',
  },
};

export const dateRangeFilters = [
  {
    id: '0',
    ...dateRangePresetsMap.today,
  },
  {
    id: '1',
    ...dateRangePresetsMap.yesterday,
  },
  {
    id: '2',
    ...dateRangePresetsMap.thisWeek,
  },
  {
    id: '3',
    ...dateRangePresetsMap.lastWeek,
  },
  {
    id: '4',
    ...dateRangePresetsMap.last7Days,
  },
  {
    id: '5',
    ...dateRangePresetsMap.last30Days,
  },
  {
    id: '6',
    ...dateRangePresetsMap.last12Months,
  },
  {
    id: '7',
    ...dateRangePresetsMap.lastYear,
  },
  {
    id: '8',
    ...dateRangePresetsMap.allTime,
  },
];

export default dateRangeFilters;
