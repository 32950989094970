/* eslint-disable @typescript-eslint/no-explicit-any */
const baseStyles = {
  baseStyle: (props: any) => ({
    popper: {
      zIndex: 10,
      maxW: props.width ? props.width : 'xs',
      w: '100%',
    },
  }),
};

export default { baseStyles };
