const parts = ['container', 'button', 'panel'];

const baseStyleContainer = {
  borderTopWidth: '1px',
  borderColor: 'inherit',
  _last: {
    borderBottomWidth: '1px',
  },
};

const baseStyleButton = {
  display: 'flex',
  alignItems: 'center',
  fontSize: 'sm',
  fontWeight: 'bold',
  color: 'blue.500',
  textAlign: 'left',
  textTransform: 'uppercase',
  letterSpacing: 'wide',
  borderRadius: 0,
  _expanded: {
    color: 'blue.900',
  },
  _focus: {
    boxShadow: 'insetOutline',
    outline: 'none',
  },
  _hover: {
    bg: 'blue.50',
  },
  _disabled: {
    opacity: 0.4,
    cursor: 'not-allowed',
  },
  px: 4,
  py: 2,
  height: 16,
};

const baseStylePanel = {
  pt: 2,
  px: 4,
  pb: 5,
};

const baseStyle = {
  container: baseStyleContainer,
  button: baseStyleButton,
  panel: baseStylePanel,
};

export default {
  parts,
  baseStyle,
};
