const root = '/docs/tutorials';
const baseRoute = (path = '') => `${root}${path}`;
const encryptRoute = (path = '') => baseRoute(`/encrypt${path}`);
const decryptRoute = (path = '') => baseRoute(`/decrypt${path}`);

export default {
  root,
  encrypt: {
    root: encryptRoute(),
    chapter0: encryptRoute(),
    chapter1: encryptRoute('/create-subscriber'),
    chapter2: encryptRoute('/create-encryption-key'),
    chapter3: encryptRoute('/create-key-packet'),
    chapter4: encryptRoute('/store-key-packet'),
    chapter5: encryptRoute('/encrypt-message'),
  },
  decrypt: {
    root: decryptRoute(),
    chapter0: decryptRoute(),
    chapter1: decryptRoute('/fetch-encryption-key'),
    chapter2: decryptRoute('/decrypt-message'),
  },
} as const;
