import { mode, getColor } from '@chakra-ui/theme-tools';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Dict = Record<string, any>;

const parts = ['track', 'thumb'];

function baseStyleTrack(props: Dict) {
  const { colorScheme: c, theme } = props;

  return {
    borderRadius: 'full',
    p: '2px',
    transition: 'all 120ms',
    bg: mode('gray.300', 'whiteAlpha.400')(props),
    _focus: {
      boxShadow: `0 0 0 3px ${getColor(theme, 'gray.100')}`,
    },
    _disabled: {
      opacity: 0.4,
      cursor: 'not-allowed',
    },
    _checked: {
      bg: mode(`${c}.500`, `${c}.200`)(props),
      _focus: {
        boxShadow: `0 0 0 3px ${getColor(theme, `${c}.200`)}`,
      },
    },
  };
}

const baseStyleThumb = {
  bg: 'white',
  transition: 'transform 250ms',
  borderRadius: 'full',
  transform: 'translateX(0)',
};

const baseStyle = (props: Dict) => ({
  track: baseStyleTrack(props),
  thumb: baseStyleThumb,
});

const sizes = {
  sm: {
    track: { w: '1.375rem', h: '0.75rem' },
    thumb: {
      w: '0.75rem',
      h: '0.75rem',
      _checked: {
        transform: 'translateX(0.625rem)',
      },
    },
  },

  md: {
    track: { w: '1.875rem', h: '1rem' },
    thumb: {
      w: '1rem',
      h: '1rem',
      _checked: {
        transform: 'translateX(0.875rem)',
      },
    },
  },

  lg: {
    track: { w: '2.875rem', h: '1.5rem' },
    thumb: {
      w: '1.5rem',
      h: '1.5rem',
      _checked: {
        transform: 'translateX(1.375rem)',
      },
    },
  },
};

const defaultProps = {
  size: 'md',
  colorScheme: 'blue',
};

export default {
  parts,
  baseStyle,
  sizes,
  defaultProps,
};
