
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"1 min read","minutes":0.66,"time":39600,"words":132}
/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "api-reference"
    }}>{`API Reference`}</h1>
    <h2 {...{
      "id": "introduction"
    }}>{`Introduction`}</h2>
    <p>{`Donec sed tristique massa. Morbi convallis ante quam. Sed pulvinar pretium faucibus. Fusce imperdiet ligula a lectus dictum placerat. Quisque eget nunc justo. Donec mattis commodo risus, at vestibulum nisi auctor id. Cras at odio vel lectus vehicula condimentum. Fusce mi tellus, viverra vel lacus et, dictum consectetur justo. Etiam eu consectetur leo. Integer non metus sed nisi fringilla sodales sit amet at nisi. Donec euismod augue urna. In ac metus id quam tincidunt sollicitudin in nec diam. Nullam sed efficitur est. Duis in nibh vel felis dignissim laoreet. Aenean viverra justo purus, non accumsan nisi tempor a.`}</p>
    <p><a parentName="p" {...{
        "href": "/docs/api/quantum"
      }}>{`Quantum`}</a></p>
    <p><a parentName="p" {...{
        "href": "/docs/api/subscription"
      }}>{`Subscription`}</a></p>
    <p><a parentName="p" {...{
        "href": "/docs/api/validation"
      }}>{`Validation`}</a></p>
    <h2 {...{
      "id": "client-sdks"
    }}>{`Client SDKs`}</h2>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "introduction",
    level: 2,
    title: "Introduction",
    children: [
        
      ]
  },
{
    id: "client-sdks",
    level: 2,
    title: "Client SDKs",
    children: [
        
      ]
  }
]

export const frontMatter = {
  "title": "Getting Started",
  "description": "How to get started with XQ key management",
  "route": "/docs/api/intro",
  "disableTableOfContents": false
}

