import { mode } from '@chakra-ui/theme-tools';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Dict = Record<string, any>;

const parts = ['item', 'command', 'list', 'button', 'groupTitle'];

function baseStyleList(props: Dict) {
  return {
    bg: mode(`#fff`, `gray.700`)(props),
    boxShadow: mode(`2xl`, `dark-lg`)(props),
    color: 'inherit',
    minW: '3xs',
    py: 2,
    zIndex: 'docked',
    borderRadius: 'md',
    borderWidth: 0,
    overflow: 'hidden',
  };
}

function baseStyleItem(props: Dict) {
  return {
    fontSize: 'sm',
    py: 2,
    px: 4,
    transition: 'background 50ms ease-in 0s',
    _focus: {
      bg: mode(`blue.50`, `whiteAlpha.200`)(props),
    },
    _hover: {
      bg: mode(`blue.50`, `whiteAlpha.200`)(props),
    },
    _active: {
      bg: mode(`gray.200`, `whiteAlpha.200`)(props),
    },
    _expanded: {
      bg: mode(`gray.100`, `whiteAlpha.100`)(props),
    },
    _disabled: {
      opacity: 0.4,
      cursor: 'not-allowed',
    },
  };
}

const baseStyleGroupTitle = {
  mx: 4,
  my: 2,
  fontWeight: 'semibold',
  fontSize: 'sm',
};

const baseStyleCommand = {
  opacity: 0.6,
};

const baseStyle = (props: Dict) => {
  return {
    list: baseStyleList(props),
    item: baseStyleItem(props),
    groupTitle: baseStyleGroupTitle,
    command: baseStyleCommand,
  };
};

export default {
  parts,
  baseStyle,
};
