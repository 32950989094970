import { useCallback, useEffect, useReducer, useRef } from 'react';
import isEqual from 'lodash/isEqual';
import { SearchEventLogsResponse } from 'backend/api-types/dashboard';
import { dashboardService, timezoneOffset } from 'backend/services';
import useAsyncAction from 'shared/hooks/useAsyncAction';
import { convertEventToLogItem, EventLogItem } from 'event-log/types';
import { EventLogFilters } from './useEventFilters';

const DEFAULT_PAGE_SIZE = 50;

export enum EventThreatLevel {
  Benign = 0,
  Info,
  Warn,
  Alert,
  Critical,
}

export type EventThreatGroup = 'unknown' | 'good' | 'warn' | 'threat';

// Group multiple EventThreatLevel under a descriptive label
export const eventThreatLevelsByGroup: Record<
  EventThreatGroup,
  EventThreatLevel[]
> = {
  unknown: [EventThreatLevel.Benign],
  good: [EventThreatLevel.Info],
  warn: [EventThreatLevel.Warn],
  threat: [EventThreatLevel.Alert, EventThreatLevel.Critical],
};

// Look up threat labels by EventThreatLevel
export const eventThreatGroupsByLevel: Record<
  EventThreatLevel,
  EventThreatGroup
> = Object.entries(eventThreatLevelsByGroup).reduce(
  (accGroups, [group, levels]) => {
    return {
      ...accGroups,
      ...levels.reduce((accLevels, level) => {
        return {
          ...accLevels,
          [level]: group,
        };
      }, {}),
    };
  },
  {
    [EventThreatLevel.Benign]: 'unknown',
    [EventThreatLevel.Info]: 'unknown',
    [EventThreatLevel.Warn]: 'unknown',
    [EventThreatLevel.Alert]: 'unknown',
    [EventThreatLevel.Critical]: 'unknown',
  }
);

type State = {
  data: Array<EventLogItem> | null;
  total: number;
  page: number;
};

enum EventLogActionType {
  DataLoaded = 'DATA_LOADED',
  Reset = 'RESET',
}

type Action = {
  type: EventLogActionType;
  data?: Array<EventLogItem> | null;
  total?: number;
  page?: number;
};

const initialState: State = {
  data: null,
  total: 0,
  page: 0,
};

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case EventLogActionType.DataLoaded: {
      const newData =
        action.data?.reduce(
          (acc, curr) => {
            // Ensure uniqueness of the events in case they are still streaming in
            // as the page cursor moves.
            if (!state.data?.find((i) => i.id === curr.id)) {
              acc.push(curr);
            }

            return acc;
          },
          [...(state.data ?? [])]
        ) ?? [];

      return {
        data: newData,

        // The last page returns a total of zero. This will prevent that from overwriting
        // the actual total.
        total: action.total ? action.total : state.total,

        // Only update the current page if it is larger than the existing value.
        // This will handle any race conditions if multiple 'loadMore' are called
        // at the same time.
        page:
          action.page && action.page > state.page ? action.page : state.page,
      };
    }
    case EventLogActionType.Reset:
      return {
        ...initialState,
      };
    default:
      throw new Error('Un-Implemented action type');
  }
}

type FetchDataArguments = [filters: EventLogFilters, pageSize: number];

export default function useEventLog(
  filters: EventLogFilters = {},
  pageSize = DEFAULT_PAGE_SIZE,
  lazy = false
) {
  const filterRef = useRef<EventLogFilters | null>(null);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [fetch, loading, { error }] = useAsyncAction<
    SearchEventLogsResponse,
    FetchDataArguments
  >(
    useCallback(
      async (f: EventLogFilters, page: number) => {
        const res = await dashboardService.get<SearchEventLogsResponse>(
          '/log',
          {
            params: {
              page,
              user: f.user?.join(',') || undefined,
              limit: pageSize,
              from: f.dateRange?.fromDate?.getTime(),
              to: f.dateRange?.toDate?.getTime(),
              threat: f.threatLevels?.join(',') || undefined,
              keyid: f.apiKeys?.join(',') || undefined,
              appid: f.apps?.join(',') || undefined,
              action: f.eventTypes?.map((e) => `^${e}$`).join('|') || undefined,
              data: f.relatedTo || undefined,
              search: f.search || undefined,
              tz: timezoneOffset,
            },
          }
        );

        return res.data;
      },
      [pageSize]
    ),

    // On Success we want to append the existing data and update the page cursor
    useCallback(
      (res: SearchEventLogsResponse, [, page]: FetchDataArguments) => {
        dispatch({
          type: EventLogActionType.DataLoaded,
          data: res.logs.map(convertEventToLogItem),
          total: res.total,
          page,
        });
      },
      []
    )
  );

  useEffect(() => {
    if (!isEqual(filterRef.current, filters)) {
      // If the filters have changed, we need to clear that data that is already loaded before replacing
      // with the updated data.
      if (!isEqual(state, initialState)) {
        dispatch({
          type: EventLogActionType.Reset,
        });
      }

      // Start fetching the data as soon as the component mounts or if the filters change
      if (!lazy) {
        fetch(filters, 0);
      }

      // Update the filter ref for the future. This is required to do a deep equal on the filters
      // and prevent a render cycle when non-memoized filters are provided.
      filterRef.current = filters;
    }
  }, [fetch, filters, lazy, state]);

  const hasData = Array.isArray(state.data) && state.data.length > 0;
  const isInitialLoading = !state.data && loading;

  const loadMore = useCallback(() => {
    fetch(filters, hasData ? state.page + 1 : 0);
  }, [fetch, filters, hasData, state.page]);

  return {
    data: state.data,
    total: state.total,
    loading,
    error,
    loadMore,
    hasData,
    isInitialLoading,
  };
}
