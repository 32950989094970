
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"1 min read","minutes":0.29,"time":17400,"words":58}
/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "subscribers"
    }}>{`Subscribers`}</h1>
    <p>{`What are subscribers and why are they used`}</p>
    <h2 {...{
      "id": "creating-a-subscriber"
    }}>{`Creating a subscriber`}</h2>
    <p>{`How to create a subscriber`}</p>
    <h2 {...{
      "id": "revoking-a-subscriber"
    }}>{`Revoking a subscriber`}</h2>
    <p>{`How to revoke a subscriber`}</p>
    <h2 {...{
      "id": "other-subscriber-actions"
    }}>{`Other subscriber actions`}</h2>
    <p>{`Other actions that can be done to a subscriber`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "creating-a-subscriber",
    level: 2,
    title: "Creating a subscriber",
    children: [
        
      ]
  },
{
    id: "revoking-a-subscriber",
    level: 2,
    title: "Revoking a subscriber",
    children: [
        
      ]
  },
{
    id: "other-subscriber-actions",
    level: 2,
    title: "Other subscriber actions",
    children: [
        
      ]
  }
]

export const frontMatter = {
  "title": "Subscription Management",
  "description": "Subscription Management",
  "route": "/docs/subscribers",
  "disableTableOfContents": false
}

