export default {
  global: {
    'html, body, #root': {
      minHeight: '100vh',
      overflow: {
        base: 'visible',
        // Dave Kreut fix layout overflow scroll bug: this prevents the logged out state from being able to scroll. Add it back in if causes issues, but most likely not needed
        // lg: 'hidden',
      },
    },
    body: {
      fontFamily: 'body',
      color: 'black',
    },
    '*, *::before, *::after': {
      borderColor: 'gray.200',
    },
    '*::placeholder': {
      color: 'primary',
    },
  },
};
