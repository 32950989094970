import React, { createContext, useContext } from 'react';
import Cache from '../cache/Cache';

export interface GlobalStoreValues {
  cache: Cache;
}

export const globalCache = new Cache();

const store = {
  cache: globalCache,
};

const GlobalStoreContext = createContext<GlobalStoreValues>(store);

export const GlobalStoreContextProvider: React.FC = ({ children }) => {
  return (
    <GlobalStoreContext.Provider value={store}>
      {children}
    </GlobalStoreContext.Provider>
  );
};

export default GlobalStoreContext;

export function useGlobalStore() {
  return useContext(GlobalStoreContext);
}
