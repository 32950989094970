const baseStyle = {
  fontSize: 'md',
  mr: 3,
  mb: 1,
  fontWeight: 'bold',
  color: 'gray.700',
  transition: 'all 0.2s',
  opacity: 1,
  _disabled: {
    opacity: 0.4,
  },
};

export default {
  baseStyle,
};
