const root = '/communications';
const baseRoute = (path = '') => `${root}${path}`;

export default {
  root,
  list: {
    user: baseRoute('/user'),
    team: baseRoute('/team'),
  },
  detail: (id: string | number = ':keyPacketId') => baseRoute(`/${id}`),
} as const;
