import { combineReducers } from '@reduxjs/toolkit';

import mapSlice from './map';
import logsSlice from './logs';
import policyManagerSlice from './rules';

export const rootReducer = combineReducers({
  map: mapSlice,
  logs: logsSlice,
  policyManager: policyManagerSlice,
});
