
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"1 min read","minutes":0.175,"time":10500,"words":35}
/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "sample-applications"
    }}>{`Sample Applications`}</h1>
    <p>{`Sample applications`}</p>
    <h2 {...{
      "id": "application-1"
    }}>{`Application 1`}</h2>
    <p>{`Sample application 1`}</p>
    <h2 {...{
      "id": "application-2"
    }}>{`Application 2`}</h2>
    <p>{`Sample application 2`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "application-1",
    level: 2,
    title: "Application 1",
    children: [
        
      ]
  },
{
    id: "application-2",
    level: 2,
    title: "Application 2",
    children: [
        
      ]
  }
]

export const frontMatter = {
  "title": "Sample Applications",
  "description": "Sample XQ Applications",
  "route": "/docs/sample-applications",
  "disableTableOfContents": false
}

