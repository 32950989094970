
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"1 min read","minutes":0.26,"time":15600.000000000002,"words":52}
/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "web-hooks"
    }}>{`Web Hooks`}</h1>
    <p>{`What are webhooks and why`}</p>
    <h2 {...{
      "id": "registering-a-webhooks"
    }}>{`Registering a webhooks`}</h2>
    <p>{`How to register a new web hook.`}</p>
    <h2 {...{
      "id": "event-types"
    }}>{`Event types`}</h2>
    <p>{`What are the different event types`}</p>
    <h2 {...{
      "id": "example-payloads"
    }}>{`Example Payloads`}</h2>
    <p>{`Sample payload here.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "registering-a-webhooks",
    level: 2,
    title: "Registering a webhooks",
    children: [
        
      ]
  },
{
    id: "event-types",
    level: 2,
    title: "Event types",
    children: [
        
      ]
  },
{
    id: "example-payloads",
    level: 2,
    title: "Example Payloads",
    children: [
        
      ]
  }
]

export const frontMatter = {
  "title": "Web Hooks",
  "description": "XQ Web Hooks",
  "route": "/docs/guide/webhooks",
  "disableTableOfContents": false
}

