export interface ColorHues {
  50: string;
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
}

const blue: ColorHues = {
  50: 'hsl(209, 100%, 95%)',
  100: 'hsl(209, 100%, 90%)',
  200: 'hsl(209, 100%, 80%)',
  300: 'hsl(209, 100%, 70%)',
  400: 'hsl(209, 100%, 60%)',
  500: 'hsl(209, 100%, 50%)',
  600: 'hsl(209, 100%, 40%)',
  700: 'hsl(209, 100%, 30%)',
  800: 'hsl(209, 100%, 20%)',
  900: 'hsl(209, 100%, 10%)',
};

const red: ColorHues = {
  50: 'hsl(0, 100%, 98%)',
  100: 'hsl(0, 95%, 92%)',
  200: 'hsl(0, 97%, 85%)',
  300: 'hsl(0, 95%, 75%)',
  400: 'hsl(0, 88%, 68%)',
  500: 'hsl(0, 76%, 57%)',
  600: 'hsl(0, 61%, 48%)',
  700: 'hsl(0, 56%, 39%)',
  800: 'hsl(0, 54%, 33%)',
  900: 'hsl(357, 62%, 24%)',
};

const orange: ColorHues = {
  50: 'hsl(40, 100%, 97%)',
  100: 'hsl(39, 96%, 89%)',
  200: 'hsl(38, 93%, 77%)',
  300: 'hsl(33, 90%, 65%)',
  400: 'hsl(27, 84%, 57%)',
  500: 'hsl(24, 75%, 50%)',
  600: 'hsl(20, 71%, 44%)',
  700: 'hsl(16, 65%, 37%)',
  800: 'hsl(14, 61%, 30%)',
  900: 'hsl(14, 60%, 25%)',
};

const green: ColorHues = {
  50: 'hsl(140, 60%, 96%)',
  100: 'hsl(141, 65%, 92%)',
  200: 'hsl(141, 63%, 88%)',
  300: 'hsl(141, 64%, 80%)',
  400: 'hsl(140, 64%, 73%)',
  500: 'hsl(140, 64%, 61%)',
  600: 'hsl(140, 42%, 48%)',
  700: 'hsl(140, 42%, 36%)',
  800: 'hsl(141, 42%, 24%)',
  900: 'hsl(138, 42%, 6%)',
};

const aliases: Record<string, ColorHues> = {
  primary: blue,
  success: green,
  warning: orange,
  danger: red,
};

const colors = {
  transparent: 'transparent',
  current: 'currentColor',
  black: '#000000',
  white: '#FFFFFF',
  lightBlue: '#29abe2',
  coolGray: '#F6F7FB',
  darkBlue: '#292f4c',
  backdrop: '#fbfcff',
  ...aliases,

  whiteAlpha: {
    50: 'rgba(255, 255, 255, 0.04)',
    100: 'rgba(255, 255, 255, 0.06)',
    200: 'rgba(255, 255, 255, 0.08)',
    300: 'rgba(255, 255, 255, 0.16)',
    400: 'rgba(255, 255, 255, 0.24)',
    500: 'rgba(255, 255, 255, 0.36)',
    600: 'rgba(255, 255, 255, 0.48)',
    700: 'rgba(255, 255, 255, 0.64)',
    800: 'rgba(255, 255, 255, 0.80)',
    900: 'rgba(255, 255, 255, 0.92)',
  },

  blackAlpha: {
    50: 'rgba(0, 0, 0, 0.04)',
    100: 'rgba(0, 0, 0, 0.06)',
    200: 'rgba(0, 0, 0, 0.08)',
    300: 'rgba(0, 0, 0, 0.16)',
    400: 'rgba(0, 0, 0, 0.24)',
    500: 'rgba(0, 0, 0, 0.36)',
    600: 'rgba(0, 0, 0, 0.48)',
    700: 'rgba(0, 0, 0, 0.64)',
    800: 'rgba(0, 0, 0, 0.80)',
    900: 'rgba(0, 0, 0, 0.92)',
  },

  gray: {
    50: 'hsl(240, 3%, 94%)',
    100: 'hsl(210, 3%, 87%)',
    200: 'hsl(220, 4%, 69%)',
    300: 'hsl(225, 4%, 56%)',
    400: 'hsl(222, 4%, 44%)',
    500: 'hsl(224, 6%, 38%)',
    600: 'hsl(227, 6%, 30%)',
    700: 'hsl(230, 5%, 23%)',
    800: 'hsl(216, 6%, 15%)',
    900: 'hsl(240, 5%, 4%)',
  },

  red,
  orange,
  green,
  blue,
};

export type Colors = typeof colors;

export default colors as Colors;
