import { configureStore } from '@reduxjs/toolkit';
import { rootReducer } from './reducers/index';

export type RootState = ReturnType<typeof rootReducer>;
export default configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
